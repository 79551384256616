import React, {Component} from "react";
import {graphql, Link, StaticQuery} from "gatsby"
import hameyie from "../../../static/images/me-min.svg";
import config from "../../../data/SiteConfig";
import "./MenuBar.scss";

class MenuBar extends Component {
    render() {
        return (
            <nav className="navbar navbar-light bg-light fixed-top row">
                <Link className="navbar-brand no-shadow col-6" to="/">
                    <img className="rounded-circle" src={hameyie} alt="bhameyie"/>
                    <span className="brand-title">bhameyie</span>
                </Link>

                {config.menuLinks.map(link=>
                    (
                        <span className="navbar-nav nav-item col-1">
                            <Link className="nav-link" to={link.link}>{link.name}
                            </Link>
                        </span>
                    )
                )}

            </nav>
        );
    }
}

export default MenuBar;

