const year = new Date().getFullYear();
const author= "Boguste Hameyie";
const config = {
    siteTitle: "Boguste Hameyie", // Site title.
    siteTitleShort: "Personal", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
    siteTitleAlt: "Journey into Software", // Alternative site title for SEO.
    siteLogo: "/images/bhameyie.jpeg", // Logo used for SEO and manifest.
    siteUrl: "https://bhameyie.com", // Domain of your website without pathPrefix.
    pathPrefix: "/", // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
    siteDescription: "Journey into Software and Beyond", // Website description used for RSS feeds/meta description tag.
    siteRss: "/rss.xml", // Path to the RSS file.
    siteRssTitle: "BHameyie RSS feed", // Title of the RSS feed
    siteFBAppID: "", // FB Application ID for using app insights
    googleAnalyticsID: "UA-57205661-1", // GA tracking ID.
    disqusShortname: "47roninblog", // Disqus shortname.
    dateFromFormat: "YYYY-MM-DD", // Date format used in the frontmatter.
    dateFormat: "DD/MM/YYYY", // Date format for display.
    postsPerPage: 4, // Amount of posts displayed per listing page.
    userName: author, // Username to display in the author segment.
    userEmail: "muzzled.coder@gmail.com", // Email used for RSS feed's author segment
    userTwitter: "bhameyie", // Optionally renders "Follow Me" in the UserInfo segment.
    userLocation: "Somewhere over the rainbow", // User location to display in the author segment.
    userDescription:
        "Pragmatic Software Engineer, Tinkerer, Tea Drinker. Opinions are my own.", // User description to display in the author segment.
    // Links to social profiles/projects you want to display in the author segment/navigation bar.
    userLinks: [
        {
            label: "GitHub",
            url: "https://github.com/bhameyie",
            iconClassName: "fab fa-github",
            svgIcon: "039-github"
        },
        {
            label: "Twitter",
            url: "https://twitter.com/bhameyie",
            iconClassName: "fab fa-twitter",
            svgIcon: "013-twitter-1"
        },
        {
            label: "LinkedIn",
            url: "https://www.linkedin.com/in/bhameyie",
            iconClassName: "fab fa-linkedin",
            svgIcon: "031-linkedin"
        }
    ],
    menuLinks: [
        {
            name: 'ME',
            link: '/',
        },
        {
            name: 'BLOG',
            link: '/blog'
        }
    ],
    copyright: `Copyright ©${year}. ${author}`, // Copyright string for the footer of the website and RSS feed.
    themeColor: "#c62828", // Used for setting manifest and progress theme colors.
    backgroundColor: "#e0e0e0" // Used for setting manifest background color.
};

// Validate

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === "/") {
    config.pathPrefix = "";
} else {
    // Make sure pathPrefix only contains the first forward slash
    config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`;
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === "/")
    config.siteUrl = config.siteUrl.slice(0, -1);

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== "/")
    config.siteRss = `/${config.siteRss}`;

module.exports = config;
