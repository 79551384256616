import React from "react";
import {Helmet} from "react-helmet";
import config from "../../data/SiteConfig";
import "./index.css";
import MenuBar from "../components/MenuBar/MenuBar";
import favicon from "../../static/favicon.ico";

export default class MainLayout extends React.Component {
    render() {
        const {children} = this.props;
        return (
            <>
                <Helmet>
                    <meta name="description" content={config.siteDescription}/>
                    <link rel="icon" href={favicon} />

                    <html lang="en"/>
                </Helmet>
                <MenuBar/>
                <div className="main container">
                    {children}
                    <footer className="footer mx-auto text-center">
                        <div>
                            <h5>{config.copyright}</h5>
                        </div>
                        <div>Icons made by <a href="https://www.flaticon.com/authors/pixel-perfect"
                                              title="Pixel perfect">Pixel perfect</a> from <a
                            href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
                    </footer>
                </div>
            </>
        );
    }
}
